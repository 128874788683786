
import { computed, defineComponent, ref, watch } from 'vue'
import { single } from '@/guides/endpoints/totems'
import { useRoute } from 'vue-router'

import ArticleHeader from '@/components/article/Header.vue'
import BackBtn from '@/components/BackBtn.vue'
import Card from '@/components/inventory/Card.vue'
import Error from '@/components/Error.vue'
import MailtoCta from '@/components/MailtoCta.vue'
import Section from '@/components/inventory/Section.vue'

export default defineComponent({
  name: 'totemDetails',
  components: {
    ArticleHeader,
    BackBtn,
    Card,
    Error,
    MailtoCta,
    Section,
  },
  setup() {
    const data = ref()
    const route = useRoute()
    const slug = computed(() => route?.params?.slug)

    const folderTotem = {
      title: 'Consulte le dossier Totémisation',
      text: 'Il rassemble des infos théoriques et des idées pratiques',
    }

    const completeTotem = {
      label: 'Envie de rajouter des informations sur ce Totem?',
      mailto: 'aurelie.moreau@guides.be',
    }

    const fetch = () => {
      if (!slug.value) {
        return
      }

      single(slug.value).then(r => {
        data.value = r.data.content
      })
    }

    watch(() => slug.value, fetch)

    fetch()

    return {
      data,
      completeTotem,
      folderTotem,
    }
  },
})
