<template>
  <div class="totem fill-height" v-if="data">
    <BackBtn class="totem__back" />
    <div class="totem-inner--large">
      <ArticleHeader :data="data.header" />
      <div class="totem__content">
        <div v-if="data.body.physics">
          <h3>Caractéristiques physiques</h3>
          <div class="totem__content__text" v-html="data.body.physics"></div>
        </div>

        <div v-if="data.body.behaviour">
          <h3>Comportement et attitude</h3>
          <div class="totem__content__text" v-html="data.body.behaviour"></div>
        </div>

        <div v-if="data.body.environment">
          <h3>Environnement</h3>
          <div
            class="totem__content__text"
            v-html="data.body.environment"
          ></div>
        </div>

        <div
          v-if="
            data.body.adjectives && Object.keys(data.body.adjectives).length
          "
        >
          <h3>Adjectifs</h3>
          <ul>
            <li
              v-for="adjective in data.body.adjectives"
              :key="`adjective-${adjective.label}`"
            >
              {{ adjective.label }}
            </li>
          </ul>
        </div>

        <div v-if="data.body.insideColor">
          <h3>Couleurs de floches-intérieur</h3>
          <div
            class="totem__content__text"
            v-html="data.body.insideColor"
          ></div>
        </div>

        <div v-if="data.body.outsideColor">
          <h3>Couleurs de floches-extérieur</h3>
          <div
            class="totem__content__text"
            v-html="data.body.outsideColor"
          ></div>
        </div>

        <div v-if="data.body.synonyms">
          <h3>Autres orthographes du Totem, synonymes</h3>
          <div class="totem__content__text" v-html="data.body.synonyms"></div>
        </div>

        <div v-if="data.body.pronunciation">
          <h3>Prononciation suggérée</h3>
          <div
            class="totem__content__text"
            v-html="data.body.pronunciation"
          ></div>
        </div>

        <router-link
          class="totem__content__link"
          to="/library-card/dossier-totemisation"
        >
          <Card :data="folderTotem" />
        </router-link>

        <MailtoCta
          class="totem__content__cta"
          :data="completeTotem"
          :nameItem="data.header.title"
        />
      </div>
    </div>
    <div class="totem__extended" v-if="data.footer">
      <div class="totem-inner--large">
        <div class="totem__content">
          <h3>Suggestions de Totems</h3>

          <div class="totem__content__grid">
            <router-link
              v-for="item in data.footer"
              :key="item.url"
              class="m-xs"
              :to="item.url"
            >
              <Section :data="item" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Error />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { single } from '@/guides/endpoints/totems'
import { useRoute } from 'vue-router'

import ArticleHeader from '@/components/article/Header.vue'
import BackBtn from '@/components/BackBtn.vue'
import Card from '@/components/inventory/Card.vue'
import Error from '@/components/Error.vue'
import MailtoCta from '@/components/MailtoCta.vue'
import Section from '@/components/inventory/Section.vue'

export default defineComponent({
  name: 'totemDetails',
  components: {
    ArticleHeader,
    BackBtn,
    Card,
    Error,
    MailtoCta,
    Section,
  },
  setup() {
    const data = ref()
    const route = useRoute()
    const slug = computed(() => route?.params?.slug)

    const folderTotem = {
      title: 'Consulte le dossier Totémisation',
      text: 'Il rassemble des infos théoriques et des idées pratiques',
    }

    const completeTotem = {
      label: 'Envie de rajouter des informations sur ce Totem?',
      mailto: 'aurelie.moreau@guides.be',
    }

    const fetch = () => {
      if (!slug.value) {
        return
      }

      single(slug.value).then(r => {
        data.value = r.data.content
      })
    }

    watch(() => slug.value, fetch)

    fetch()

    return {
      data,
      completeTotem,
      folderTotem,
    }
  },
})
</script>

<style lang="scss" scoped>
.totem {
  @include details-intro;

  position: relative;
  background-color: $c-white;
}

.totem__extended {
  background-color: $athens-gray;
}

[class*='totem-inner--'],
.totem-inner {
  position: relative;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding: $spacing * 3 0;
  z-index: 0;

  &:nth-child(odd) {
    h3 {
      margin-bottom: $spacing * 0.75;
    }
  }

  @include mq(s) {
    width: calc(100% - #{$spacing * 4});
  }

  &[class*='--large'] {
    max-width: 60rem;
    @include mq(m) {
      max-width: 72rem;
    }
  }
}

[class*='totem__content--'],
.totem__content {
  margin: 0 $spacing * 1.35;

  @include mq(m) {
    margin: 0;
  }
}

.totem__content h3 {
  margin-top: $spacing * 1.5;
  margin-bottom: $spacing / 4;

  &:first-child {
    margin-top: 0;
  }
}

.totem__content div {
  margin: 0 0 $spacing * 1.5;
}

.totem__content ul {
  list-style: none;
  padding: 0;
  margin: 0 0 $spacing * 1.5;
}

.totem__content li {
  display: flex;

  & + li {
    margin-top: $spacing / 2;
  }
}

.totem__content li::before {
  content: '\2022';
  color: $regal-blue;
  display: inline-block;
  width: 6px;
  margin-right: $spacing / 2;
}

.totem__content__link {
  margin-bottom: $spacing;
  display: block;

  ::v-deep(.card) {
    background-color: $athens-gray;
  }
}

.totem__content__grid {
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(2, 1fr);

  > * {
    margin: 0;
  }

  ::v-deep(.section) {
    margin-bottom: 0;
  }
}
</style>
